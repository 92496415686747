<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>time-clock-circle-2</title>
    <path
      d="M12,4.251A7.75,7.75,0,1,0,19.75,12,7.759,7.759,0,0,0,12,4.251Zm3,9H11.5a.75.75,0,0,1-.75-.75V7a.75.75,0,0,1,1.5,0v4.75H15a.75.75,0,0,1,0,1.5Z"
    />
    <path
      d="M19.5,0H4.5A4.5,4.5,0,0,0,0,4.5v15A4.505,4.505,0,0,0,4.5,24h15A4.505,4.505,0,0,0,24,19.5V4.5A4.5,4.5,0,0,0,19.5,0Zm1.75,12A9.25,9.25,0,1,1,12,2.751,9.26,9.26,0,0,1,21.25,12Z"
    />
  </svg>
</template>
